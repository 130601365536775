<template>
<transition name="fade">
   <div class="download-file">
        <img src="/static/images/fj.png"
        class="doc-img-icon"
            width="35px"
            height="35px" />
        <div class="file-desc">
        <p class="file-name">{{fileName}}</p>
        <p>
        <a class="text-high-light"
             v-if="download"
            :href="url">下载</a>
        <a class="text-high-light"
             v-if="deleted"
             @click="onRemove"
            >删除</a>
        </p>

        </div>
    </div>
</transition>

</template>

<script>
export default {
    props: {
        fileName: {
            default: '',
            type: String
        },
        download: {
           default: true,
           type: Boolean
        },
        deleted: {
            default: false,
            type: Boolean
        },
        url: {
            default: '',
            type: String
        },
        onRemove: {
            type: Function,
            default: () => {}
        }
    }
};
</script>
<style lang="scss" >
.download-file{
    display: flex;
    height: 40px;
    align-items: center;
    font-size:14px;
    p{
        line-height: 1.5em;
    }
    .text-high-light{
        margin-right: 8px;
    }
}
</style>
