<template>
  <section class="infoBox">
    <div v-loading="pageLoading" size="mini">
      <div class="app-header__wrap" style="margin:20px 20px 5px;background-color:#fff;width: 97.5%;padding-right: 0;">
        <div class="app-header__left">
          <el-button type="text"
                    class="back-button"
                    @click="back">返回</el-button>
        </div>
        <h1 class="app-header__title">{{title}}</h1>
        <div class="app-header__right"></div>
      </div>
      <article class="sections-container recruit-plan">
        <section>
          <div class="section-wrap">
            <div class="section-content">
              <el-form label-width="117px"
                      ref="baseInfoForm"
                      :model="params">
                <el-row>
                  <el-col :span="12">
                    <el-form-item label="规划名称"
                                  prop="name"
                                  :rules="rules.name">
                      <el-input class="base-info-input" maxlength="20" show-word-limit
                                v-model="params.name"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="起止时间"
                                  prop="planStartTime"
                                  :rules="rules.planStartTime">
                      <el-date-picker type="datetimerange"
                                      class="base-info-input"
                                      :picker-options="disabledDate"
                                      :value-format="DateFormat"
                                      format="yyyy-MM-dd HH:mm"
                                      @input="dateChange"
                                      :value="dateRange"></el-date-picker>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="12">
                    <el-form-item label="一级部门"
                                  prop="orgPid"
                                  :rules="rules.orgPid">
                      <el-cascader clearable
                                  :show-all-levels="false"
                                  class="base-info-input"
                                  v-model="params.orgPid"
                                  :options="departmentOptions"
                                  :props="departmentProps"
                                  @change="changeFirstDepartment"></el-cascader>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="二级部门"
                                  prop="orgId"
                                  :rules="rules.orgId">
                      <el-cascader clearable
                                  @change="changeSubDepartment"
                                  class="base-info-input"
                                  v-model="params.orgId"
                                  :options="subDepartmetnOptions"
                                  :props="departmentProps"></el-cascader>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="12">
                    <el-form-item label="部门人数">
                      <el-input class="base-info-input"
                                :value="departPeopleCount"></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-form>
            </div>

          </div>
        </section>
        <section>
          <div class="section-wrap">
            <h3 class="title-icon">基本信息</h3>
            <div class="section-content">
              <template v-for="(item,i) in params.basicRequestList">
                <info-panel :key="i"
                            :index="i"
                            v-show="item.jobId"
                            @edit="editPositionInfo"
                            @remove="removePositionInfo"
                            :editible="true"
                            :info="item"
                            :education-options="EducationOptions"></info-panel>
                <el-divider :key="i"
                            v-if="(i+1)< params.basicRequestList.length"></el-divider>
              </template>

              <large-button icon="el-icon-plus"
                            style="margin-top:20px"
                            @click.native="addPosition">
                添加岗位信息
              </large-button>
            </div>

          </div>
        </section>
        <section>
          <div class="section-wrap section-margin-left">
            <h3 class="title-icon">岗位说明附件</h3>
            <div class="section-content">
              <el-form label-width="117px">
                <el-form-item label="岗位说明附件"
                              required
                              v-loading="uploadQueue.length>0">
                  <div>
                    <uploaded-file v-for="(file,i) in uploadedFiles"
                                  :key="i"
                                  :file-name="file.name"
                                  :download="true"
                                  :deleted="true"
                                  :url="file.url"
                                  :on-remove="()=>{ handleRemoveFile(file, nativeFileList, {fileList:uploadedFiles} , true)}"
                                  style="margin-bottom:18px;"></uploaded-file>
                  </div>
                  <el-upload :action="FileUploadAction"
                            multiple
                            :show-file-list="false"
                            class="upload-list"
                            :limit="MaxFileCount"
                            name="imgFile"
                            :before-upload="handleBeforeUpload"
                            :on-success="(res, file, filelist)=>{handleUploaded(res,file, filelist,{fileList: uploadedFiles} )}"
                            :file-list="originFileList"
                            :on-exceed="makeExceedHandler(MaxFileCount)">
                    <el-button type="primary" size="mini">上传文件</el-button>
                  </el-upload>
                  <span class="el-upload__tip">文件格式为建议上传word格式，大小在10MB以内</span>
                </el-form-item>
                <el-form-item>
                  <doc-file file-name="岗位说明书模板.docx(1.8M)"
                            url="https://ehr-prod.oss-cn-shenzhen.aliyuncs.com/template/%E5%B2%97%E4%BD%8D%E8%AF%B4%E6%98%8E%E4%B9%A6%E6%A8%A1%E7%89%88.docx"></doc-file>
                </el-form-item>
              </el-form>
            </div>
          </div>
        </section>
        <section style="margin-bottom:0">
          <div class="section-wrap section-margin-left">
            <h3 class="title-icon">审批信息</h3>
            <div class="section-content">
              <el-form ref="approvalInfo"
                      label-width="117px"
                      :model="params">
                <el-form-item label="审批人" :rules="{required:true,message:'请选择审批人',trigger:'change'}">
                  <span v-for="(tag,i) in selectedStaffs" :key="tag.id">
                    <el-tag class="tag-gap"
                          closable
                          @close="removeStaff(i)"
                          >{{tag.name}}</el-tag>
                    <i class="el-icon-arrow-right" v-show="i!=selectedStaffs.length-1"></i>
                  </span>
                  <departments btnText="添加审批人"
                              @updateStaffTag="addApprovalStaffs"
                              :show-checkbox="false"
                              :show-staff="true"></departments>
                </el-form-item>
                <!-- <el-form-item>
                </el-form-item> -->
                <el-form-item label="状态"
                              prop="status"
                              :rules="rules.status">
                  <el-radio-group v-model="params.status">
                    <el-radio v-for="(status, i) in statuses"
                              :key="i"
                              v-show="status.show"
                              :label="status.value">{{status.label}}</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-form>
            </div>
          </div>
        </section>
      </article>
      <div style="text-align: center;padding:20px;background-color:#fff;margin:0 20px">
        <el-button @click="cancelSubmitRecruitPlan">取消</el-button>
        <el-button type="primary"
                  @click="submitRecruitPlan"
                  :loading="uploadQueue.length>0">提交</el-button>
      </div>
      <el-dialog :title="dialogAction.title"
                top="20px"
                center
                :visible.sync="dialogVisible"
                width="60%"
                >
        <div v-if="dialogAction === AddPosition || dialogAction === EditPosition"
            v-loading="loading">
          <el-form label-width="130px"
                  ref="positionForm"
                  :model="positionInfo"
                  :rules="positionInfoRules">
            <el-row :gutter="20">
              <el-col :span="11">
                <el-form-item label="需求岗位"
                              prop="jobId">
                  <el-select class="full-width-input"
                            filterable
                            value-key="id"
                            v-model="currentPosition"
                            @change="changePosition">
                    <el-option v-for="(item, i) in positions"
                              :key="i"
                              :label="item.jobOrOfficeName"
                              :value="item"></el-option>
                  </el-select>

                </el-form-item>
              </el-col>
              <el-col :span="2">
                <div style="width:100%;height:100%;">&nbsp;</div>
              </el-col>
              <el-col :span="11">
                <el-form-item label="岗位现有人力"
                              prop="sumCount">
                  <el-input class="full-width-input"
                            disabled
                            :value="positionInfo.sumCount"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="11">
                <el-form-item label="部门岗位人数"
                              prop="depCount">
                  <el-input class="full-width-input"
                            disabled
                            :value="positionInfo.depCount"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="2">
                <div style="width:100%;height:100%;">&nbsp;</div>
              </el-col>
              <el-col :span="11">
                <el-form-item label="需求数量"
                              prop="number">
                  <el-input class="full-width-input"
                            v-model.number="positionInfo.number"
                            @input="handleChangeNumber(positionInfo.number)"
                            ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="11">
                <el-form-item label="需求职级"
                              prop="level">
                  <el-select class="full-width-input"
                            v-model="positionInfo.level">
                    <el-option v-for="(item, i) in positionLevels"
                              :label="item"
                              :value="item"
                              :key="i"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="2">
                <div style="width:100%;height:100%;">&nbsp;</div>
              </el-col>
              <el-col :span="11">
                <el-form-item label="需求学历"
                              prop="educationDemand">
                  <el-select class="full-width-input"
                            v-model="positionInfo.educationDemand">
                    <el-option v-for="(item, i) in EducationOptions"
                              :key="i"
                              :label="item.dictName"
                              :value="item.dictId"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="11">
                <el-form-item label="需求专业"
                              prop="professionDemand">
                  <el-input class="full-width-input"
                            v-model="positionInfo.professionDemand"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="2">
                <div style="width:100%;height:100%;">&nbsp;</div>
              </el-col>
              <el-col :span="11">
                <el-form-item label="到岗截止时间"
                              prop="arrEndTime">
                  <el-date-picker type="date"
                                  :value-format="DayFormat"
                                  style="width:100%;"
                                  :picker-options="disabledDate"
                                  v-model="positionInfo.arrEndTime"
                                  class="full-width-input"></el-date-picker>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="24">
                <el-form-item label="需求原因"
                              prop="demandReason">
                  <el-input class="full-width-input"
                            type="textarea"
                            rows="2"
                            autosize
                            placeholder="请输入需求原因"
                            show-word-limit
                            v-model="positionInfo.demandReason"
                            :maxlength="TextAreaMaxLength"></el-input>
                          <!--  <span class="lis" style="position: absolute;right:12px;bottom:-4px;">0/999</span>!-->
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="24">
                <el-form-item label="需求技能"
                              prop="skillDemand">
                  <el-input class="full-width-input"
                            type="textarea"
                            autosize
                            placeholder="请输入需求技能"
                            v-model="positionInfo.skillDemand"
                            show-word-limit
                            :maxlength="TextAreaMaxLength"></el-input>
                          <!--<span class="lis" style="position: absolute;right:12px;bottom:-4px;">0/999</span>!-->
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="24">
                <el-form-item label="其他需求"
                              prop="otherDemand">
                  <el-input class="full-width-input"
                            type="textarea"
                            placeholder="请输入其他需求"
                            v-model="positionInfo.otherDemand"
                            :maxlength="TextAreaMaxLength"
                            show-word-limit
                            autosize></el-input>
                          <!--<span class="lis" style="position: absolute;right:12px;bottom:-4px;font-size:14px;">0/999</span>!-->
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="24">
                <el-form-item label="岗位说明"
                              prop="jobSpecification">
                  <el-input class="full-width-input"
                            v-model="positionInfo.jobSpecification"
                            type="textarea"
                            autosize
                            show-word-limit
                            placeholder="请输入岗位说明书"
                            :maxlength="TextAreaMaxLength"></el-input>
                            <!--<span class="lis" style="position: absolute;right:12px;bottom:-4px;">0/999</span>!-->
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
        <div slot="footer">
          <el-button @click="cancelAddPosition">取消</el-button>
          <el-button type="primary"
                    @click="confirmAddPosition"
                    :loading="loading">保存</el-button>
        </div>
      </el-dialog>
    </div>
  </section>
</template>
<script>
import LargeButton from '../../../components/LargeButton';
import { getEducationOptions } from '../../../utils/dict.js';
import { queryDepartment } from '../../../api/workbench';
import UploadMixin from '../../../mixins/upload';
import { FileUploadAction, DayFormat, DateFormat, RequiredError } from '../../../utils/const';
import { parseTime } from '../../../utils/index';
import DocFile from '../../../components/DocFile';
import Departments from '../../../components/departs';
import InfoPanel from '../components/InfoPanel';
import { createRecruitPlan, getPositionLevelsByJob, getPositionNum, planDetatil, updateRecruitPlan } from '../../../api/manpowerplanning';
import { getPositions } from '../../../api/common';
import { findTreeElement } from '../const';
import UploadedFile from '../../../components/UploadedFile';
const AddPosition = { title: '岗位信息' };
const EditPosition = { title: '岗位信息' };

function makeDisableDate (date) {
  return { disabledDate (time) {
      return time.getTime() < date;
    }};
}
export default {
  components: {
    LargeButton,
    Departments,
    DocFile,
    UploadedFile,
    InfoPanel
  },
  mixins: [UploadMixin],
  data () {
    return {
      DayFormat,
      DateFormat,
      EditPosition,
      currentPosition: {},
      MaxFileCount: 3,
      departPeopleCount: null,
      remoteLoading: false,
      uploadedFiles: [],
      params: {
        name: '',
        orgPid: '',
        orgId: '',
        status: 0,
        planStartTime: '',
        planEndTime: '',
        basicRequestList: [],
        accessoryInfos: [],
        approvalUserIds: []
      },
      positionLevels: [],
      rules: {
        name: [{ required: true, message: '请填写计划名称', trigger: 'blur' }],
        planStartTime: [{ required: true, message: '请选择起止时间', trigger: 'blur' }],
        orgPid: [{ required: true, message: '请选择一级部门', trigger: 'blur' }],
        orgId: [{ required: true, message: '请选择二级部门', trigger: 'blur' }],
        selectedStaffs: [{required: true, type: 'array', message: '请选择审批人', trigger: 'change'}],
        status: [{ required: true, message: '请选择状态', trigger: 'blur' }]
      },
      positionInfoRules: {
        jobId: [{ required: true, message: '请选择岗位', trigger: 'blur' }],
        number: [{ required: true, type: 'number', message: '请填写需求数量', trigger: 'blur' }],
        // level: [{ required: true, message: "请选择职级", trigger: "blur" }],
        educationDemand: [{ required: true, message: '请选择学历', trigger: 'blur' }],
        professionDemand: [{ required: true, message: '请填写专业', trigger: 'blur' }],
        skillDemand: [{ required: true, message: '请填写需求技能', trigger: 'blur' }],
        arrEndTime: [{ required: true, message: '请选择到岗时间', trigger: 'blur' }],
        demandReason: [{ required: true, message: '请填写需求原因', trigger: 'blur' }]
      },
      departmentProps: {
        value: 'id',
        label: 'orgName',
        emitPath: false,
        checkStrictly: true
      },
      positionInfo: {
        jobId: '',
        number: null,
        level: '',
        educationDemand: '',
        professionDemand: '',
        skillDemand: '',
        arrEndTime: '',
        sumCount: null,
        depCount: null,
        otherDemand: '',
        demandReason: '',
        jobSpecification: ''
      },
      statuses: {
        0: {
          label: '草稿',
          value: 0,
          tip: '你确定要保存草稿?',
          show: true
        },
        3: {
          label: '已发布',
          value: 3,
          tip: '此计划已发布',
          show: false,
          forbidden: true
        },
        2: {
          label: '发布',
          value: 2,
          tip: '你确定要发布计划?',
          show: true
        },
        4: {
          label: '取消',
          value: 4,
          tip: '计划还在进行中,你要取消计划?',
          show: false
        }
      },
      selectedStaffs: [],
      originFileList: [],
      originStatus: null,
      height: '100%',
      TextAreaMaxLength: '999',
      AddPosition,
      FileUploadAction,
      dialogAction: '',
      dialogVisible: false,
      EducationOptions: [],
      disabledDate: {},
      departmentOptions: [],
      positions: [],
      loading: false,
      isBlur: true,
      pageLoading: false,
      currentPositionIndex: null,
      subDepartmetnOptions: [],
      positionParams: {
        pageNo: 1,
        totalNum: 0,
        pageSize: 3000,
        name: '',
        type: 2

      }
    };
  },
  created () {
    this.EducationOptions = getEducationOptions();
    this.disabledDate = makeDisableDate(new Date(`${parseTime(Date.now(), '{y}-{m}-{d}')} 00:00:00`));
  },
  computed: {
    dateRange: {
      set (val) {
        this.params.planStartTime = val ? val[0] : '';
        this.params.planEndTime = val ? val[1] : '';
      },
      get () {
        return [this.params.planStartTime || '', this.params.planEndTime || ''];
      }
    },
    title () {
      switch (this.$route.query.type) {
        case 'add':
          return '创建人才规划';
        case 'edit':
          return '修改人才规划';
        case 'editAgain':
          return '修改人才规划';
        case 'clone':
          return '创建人才规划';
        default:
          return '';
      }
    }
  },
  async mounted () {
    // window.onresize = (() => {
    //   this.resize();
    // })
    // this.resize();
    this.getPositions();
    const {type} = this.$route.query;

    if (type !== 'add') {
      this.pageLoading = true;
      try {
        const res = await Promise.all([this.getPlanDetails(this.$route.query.id), this.getDepartmentTreeData()]);

        if (res[0]) {
          const result = res[0];

          this.params = result;
          this.originStatus = this.params.status;
          if (type === 'clone') {
            this.params.name = '';
            this.params.status = 0;
            this.dateRange = ['', ''];
          }

          if ([2, 3].includes(this.params.status)) {
            this.statuses[4].show = true;
          }
          if (!this.statuses[this.params.status]) {
            this.params.status = 0;
          }
          if (result.approvalUserIds && result.approvalUserIds.length > 0) {
            this.selectedStaffs = result.approvalUserIds.map((v) => ({ id: v.staffId, name: v.name }));
          }
          if (result.accessoryInfos && result.accessoryInfos.length > 0) {
            this.uploadedFiles = result.accessoryInfos && result.accessoryInfos.flat();
            this.originFileList = result.accessoryInfos && result.accessoryInfos.flat();
          }
          this.params.orgId = `${result.orgId}`;
          this.params.orgPid = `${result.orgPid}`;
          if (this.params.orgPid) {
            this.changeFirstDepartment(this.params.orgPid, true);
          }
          if (this.params.orgId) {
            this.changeSubDepartment(this.params.orgId);
            const countList = await Promise.all(this.params.basicRequestList.map((v) => this.getPositionNum(v.jobId, this.params.orgId)));

            countList.forEach((v, i) => {
              if (v) {
                this.$set(this.params.basicRequestList[i], 'depCount', v.depCount);
                this.$set(this.params.basicRequestList[i], 'sumCount', v.sumCount);
              }
            });
          }
        }
      } finally {
        this.pageLoading = false;
      }
    } else {
      await this.getDepartmentTreeData();
    }
  },
  methods: {
    handleChangeNumber(content) {
      if (isNaN(content)) {
        this.positionInfo.number = '';
      }
    },
    async getPlanDetails (id) {
      return planDetatil({ id }).then((res) => {
        if (res._responseStatusCode === 0) {
          return res;
        }
          return null;

      });
    },
    async changePosition (val, review = false) {
      this.positionInfo.jobName = val.jobOrOfficeName;
      this.positionInfo.jobId = val.id;
      if (!review) {
        this.positionInfo.level = null;
        this.positionInfo.depCount = null;
        this.positionInfo.sumCount = null;
      }
      this.loading = true;
      try {
        const res = await Promise.all([
          this.getPositionLevelsByJob(val.id),
          this.getPositionNum(val.id, this.params.orgId)
        ]);

        if (res[1]) {
          this.positionInfo.sumCount = res[1].sumCount;
          this.positionInfo.depCount = res[1].depCount;
        }
      } finally {
        this.loading = false;
      }
    },
    async getPositions (name = '') {
      this.positionParams.name = name;
      this.positionParams.pageNo = 1;
      return getPositions(this.positionParams).then((res) => {
        if (res._responseStatusCode === 0) {
          this.positions = res.list;
          this.positionParams.totalNum = res.totalNum;
        }
      });
    },
    loadMore () {
      if (this.positionParams.totalNum == 0 || this.positions.length < this.positionParams.totalNum) {
        ++this.positionParams.pageNo;
        // this.remoteLoading= true;
        getPositions(this.positionParams).then((res) => {
          // this.remoteLoading = false;
          if (res._responseStatusCode === 0) {
            res.list.forEach((v) => {
              this.positions.push(v);
            });
            this.positionParams.totalNum = res.totalNum;
          }
        });
      }
    },
    async getPositionLevelsByJob (jobId) {
      this.positionLevels = [];
      return getPositionLevelsByJob({ jobId }).then((res) => {
        if (res._responseStatusCode === 0) {
          this.positionLevels = res;
          return true;
        }
          return Promise.reject(false);

      });
    },
    async getPositionNum (jobId, orgId) {
      return getPositionNum({
        jobId, orgId
      }).then((res) => {
        if (res._responseStatusCode === 0) {
          return res;
        }
          return Promise.reject(false);

      });
    },
    cancelAddPosition () {
      this.dialogVisible = false;
    },
    async createPlan (params) {
      return createRecruitPlan(params).then((res) => {
        if (res._responseStatusCode === 0) {
          return true;
        }
          return false;

      });
    },
    async updateRecruitPlan (params) {
      return updateRecruitPlan(params).then((res) => {
        if (res._responseStatusCode === 0) {
          return true;
        }
          return false;

      });
    },
    confirmAddPosition () {
      this.$refs.positionForm.validate((valid) => {
        if (valid) {
          const clone = JSON.parse(JSON.stringify(this.positionInfo));

          if (this.dialogAction === AddPosition) {
            this.params.basicRequestList.push(clone);
          } else if (this.dialogAction === EditPosition) {
            this.params.basicRequestList[this.currentPositionIndex] = clone;
          }
          this.dialogVisible = false;
        }
      });
    },
    resize () {
      const appMain = document.getElementById('appMain');

      if (appMain) {
        this.height = `${appMain.clientHeight + 50}px`;
      }
    },
    back () {
      this.$router.back();
    },
    cancelSubmitRecruitPlan () {
      this.$confirm('您确认要退出？', '提示', {type: 'warning'}).then(() => {
        this.$router.back();
      });
    },
    removeStaff (index) {
      this.selectedStaffs.splice(index, 1);
    },
    submitRecruitPlan () {
      const status = this.statuses[this.params.status];

      const {tip} = status;

      if (this.params.status == 0 && !this.params.name) {
        this.$refs.baseInfoForm.validateField('name');
          return false;
        }
      this.$confirm(tip, '提示', {type: 'warning'}).then(async () => {
        if (status.forbidden) {
          return;
        }

        this.params.approvalUserIds = this.selectedStaffs.map((v) => v.id);
        if (this.params.approvalUserIds.length === 0 && this.params.status !== 0) {
          this.$message.error({
            message: '请选择审批人!'
          });
          return;
        }
        if (this.params.status !== 0) {
          try {
            this.$refs.baseInfoForm.validate((valid) => {
              if (!valid) {
                throw new RequiredError('请完善计划信息');
              }
            });
          } catch (err) {
            if (err instanceof RequiredError) {
              this.$message.error({
                message: err.message
              });
            }
            return;
          }
        }
        this.params.accessoryInfos = this.uploadedFiles.map((v) => JSON.stringify([v]));
        if (this.params.accessoryInfos.length === 0 && this.params.status !== 0) {
          this.$message.error({
            message: '请上传岗位说明附件!'
          });
          return;
        }
        if (this.params.basicRequestList.length === 0 && this.params.status !== 0) {
          this.$message.error({
            message: '请添加岗位信息!'
          });
          return;
        }

        try {
          this.loading = true;
          let res = null;

          const {type} = this.$route.query;

          if (['add', 'clone'].includes(type)) {
            res = await this.createPlan(this.params);
          } else if (['edit', 'editAgain'].includes(type)) {
            res = await this.updateRecruitPlan(this.params);
          }
          if (res) {
            this.$message.success({
              message: `${this.title}成功!`
            });
            this.$router.replace({ path: '/mpPlan/list' });
          }
        } finally {
          this.loading = false;
        }
      });
    },
    addApprovalStaffs (staffs) {
      const newStaffs = staffs.filter((v) => this.selectedStaffs.every((cv) => cv.id != v.id));

      this.selectedStaffs = this.selectedStaffs.concat(newStaffs);
      // console.log
      // this.selectedStaffs.map(it=>{
      //   staffs.map(it=>{})
      //   if(it.id!=staffs[0].staffId){
      //     this.selectedStaffs = this.selectedStaffs.concat(staffs);
      //   }
      // })
    },
    changeSubDepartment (id) {
      const res = findTreeElement(id, this.subDepartmetnOptions);

      if (res) {
        this.departPeopleCount = res.countNumber;
      }
    },
    changeFirstDepartment (id, review = false) {
      this.departPeopleCount = null;
      const res = this.getSubDepartmentTreeData(id, this.departmentOptions);

      if (res && res.children && res.children.length > 0) {
        if (!review) {
          this.params.orgId = null;
        }
        this.subDepartmetnOptions = res.children;
      } else {
        this.params.orgId = id;
        this.subDepartmetnOptions = res ? [res] : [];
        this.departPeopleCount = res ? res.countNumber : null;
      }
    },
    getSubDepartmentTreeData (parentId, data) {
      const res = findTreeElement(parentId, data);

      return res;
    },
    async getDepartmentTreeData () {
      return queryDepartment({ request: {} }).then((res) => {
        if (res._responseStatusCode === 0) {
          this.departmentOptions = res;
        }
      });
    },
    async openDialog (action) {
      this.dialogAction = action;
      this.dialogVisible = true;
      this.$nextTick(() => {
        this.$refs.positionForm.clearValidate();
      });
      // if (action === AddPosition) {
      //   this.getPositions();
      // } else if (action === EditPosition) {
      //   await this.getPositions();
      //   this.positions.unshift(this.currentPosition);
      // }
    },
    dateChange (val) {
      this.dateRange = val;
    },
    resetPositionInfo () {
      this.positionInfo = {
        jobId: '',
        number: null,
        level: '',
        educationDemand: '',
        professionDemand: '',
        skillDemand: '',
        arrEndTime: '',
        sumCount: null,
        depCount: null,
        otherDemand: '',
        demandReason: '',
        jobSpecification: ''
      };
    },
    editPositionInfo (index) {
      this.currentPositionIndex = index;
      this.openDialog(EditPosition);
      this.positionInfo = JSON.parse(JSON.stringify(this.params.basicRequestList[index]));
      this.currentPosition = this.findPosition(this.params.basicRequestList[index].jobId);
      this.changePosition(this.currentPosition, true);
    },
    removePositionInfo (index) {
      this.params.basicRequestList.splice(index, 1);
    },
    findPosition (id) {
      return this.positions.find((v) => v.id == id) || {};
    },
    addPosition () {
      if (!this.params.orgId) {
        this.$message.error({
          message: '请先选择部门!'
        });
        return;
      }
      // this.positions = [];
      this.currentPosition = {};
      this.resetPositionInfo();
      this.openDialog(AddPosition);
    }
  }


};
</script>
<style lang="scss">
// .el-dialog{
//   width: 60%;
// }
// .el-textarea .el-input__count {
//     color: #ccc;
//     background: transparent;
//     position: absolute;
//     font-size: 12px;
//     bottom: 0px;
//     right: 10px;
// }
.app-header__wrap {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 14px 40px;
  height: 43px;
}
.app-header__left {
  .back-button {
    color: #484848;
    font-size: 16px;
  }
}
.app-header__title {
  font-weight: bold;
  color: #484848;
  font-size: 18px;
}
</style>
<style lang="scss">
.infoBox {
    .el-dialog{
  .el-dialog__body{
    padding:30px 50px 0px 0;
  }
  .el-dialog__footer{
    div{
      text-align: center;
    }
  }
}
.upload-list {
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-start;
  .el-upload-list {
    width: 600px;
  }
}
.tag-gap {
  margin-bottom: 8px;
}
.full-width-input {
  width: 100%;
}
.required-field {
  &:after {
    content: "*";
    color: red;
  }
}
.recruit-plan {
  .el-form-item__label {
    font-size: 14px;
    font-weight: normal;
    color: #777e8c;
    &:after {
      content: ":";
    }
  }
  .base-info-input {
    width: 95%;
  }
}
.sections-container {
  background-color: #f6f7fb;
  overflow: hidden;
  .title-icon {
    font-size: 16px;
    margin-bottom: 20px;
    &:before {
      display: inline-block;
      width: 8px;
      height: 18px;
      background: #498df0;
      content: " ";
      border-radius: 5px;
      vertical-align: top;
      margin-right: 5px;
    }
  }
  section {
    margin: 20px;
    border-radius: 4px;
    padding: 20px;
    background-color: #fff;
  }
  .section-wrap {
    padding: 0 35px;
  }
  .section-content {
    width: calc(95%);
    margin: 0 auto;
  }
  .section-margin-left{
    .el-form-item{
      margin:0;
    }
  }
  .el-form-item__content{
      // position: relative;
      .lis{
        position: absolute;
        right: 0px;
        bottom: 0px;
      }
  }
}
}

</style>
