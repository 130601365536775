import Store from '../store';
const Dict = Store.state.app.dict;
function filterDict(groupId){
  return Dict.filter(v => {
    return v.groupId === groupId;
  })||[];
}
export function getDictName(dictId, data){
  let res = data.find(v=>v.dictId===dictId);
  if(res){
    return res.dictName
  }else {
    return "";
  }
}
// 获取教育学历选项数据
export function getEducationOptions(){
  return filterDict('highestEducation');
}
// 获取自评答辩报告模板文件
export function getEvaluationTemplates(){
    return Dict.filter(item => item.groupId === 'selfEvaluationManage' || item.groupId === 'selfEvaluationNotManage') || [];
}
// 获取审批类型列表
export function getSubTypeOptions(){
  return filterDict('subType');
}
// 获取审批状态列表
export function getApprovalStatusOptions(){
  return filterDict('approvalStatus');
}

// 人力规划计划状态
export function getManPowerPlanStatus(){
  return filterDict('ManPowerPlanStatus');
}
// 人力规划结果列表状态
export function getManPowerApprovalStatus(){
  return filterDict('ManPowerApprovalStatus');
}