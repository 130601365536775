export default {
    data(){
        return {
            uploadQueue:[],
            nativeFileList:[]
        }
    },
    methods:{
        handleUploaded (res, file, filelist, item) {
            this.uploadQueue.shift();
            if (res.code === 0) {
                this.$notify({
                    type: "success",
                    message: "上传成功"
                });
                let resFileMeta = {
                    name: res.data.fileName,
                    size: file.size,
                    url: res.data.httpUrl,
                    bucketName: res.data.bucketName,
                    key: res.data.key
                };
                item.fileList.push(resFileMeta);
            } else {
                this.$notify({
                    type: "error",
                    message: "上传失败"
                });
                filelist.pop();
            }
            this.nativeFileList = filelist;
        },
        makeExceedHandler(maxFileCount=3){
            return function (files, filelist) {
                if (
                    files.length > maxFileCount ||
                    filelist.length === maxFileCount ||
                    files.length + filelist.length > maxFileCount
                ) {
                    this.$message({
                        message: `上传文件数量不能超过${maxFileCount}个`,
                        type: "warning"
                    });
                }
            }
        },
        handleExceed (files, filelist, maxFileCount=3) {
            if (
                files.length > maxFileCount ||
                filelist.length === maxFileCount ||
                files.length + filelist.length > maxFileCount
            ) {
                this.$message({
                    message: `上传文件数量不能超过${maxFileCount}个`,
                    type: "warning"
                });
            }
        },
        handleBeforeUpload (file) {
            this.uploadQueue.push(1);
            return file;
        },
        handleRemoveFile (file, filelist, item, removeNativeFile=false) {
            if (file.status === "uploading") {
                this.uploadQueue.pop();
            }
            let fileKey = "";
            if (file.response) {
                fileKey = file.response.data.key;
            } else {
                fileKey = file.key;
            }
            let index = item.fileList.findIndex(v => v.key === fileKey);
            if (index !== -1) {
                item.fileList.splice(index, 1);
            }
            if (removeNativeFile){
                filelist.splice(index,1 );
            }
        },
    }
}