<template>
  <div class="info-panel">
    <div class="info-panel__header">
      <h4 class="info-title">{{info.jobName}}岗位信息</h4>
      <div v-if="editible">
        <el-button type="text"
                   icon="el-icon-edit"
                   @click="edit">编辑</el-button>
        <el-button type="text"
                   icon="el-icon-delete"
                   style="color:red;"
                   @click="remove">删除</el-button>
      </div>
    </div>
    <div class="info-content">
      <el-row class="info-row__2"

              type="flex">
        <el-col class="info-item"><label>需求岗位</label>
          <p>{{info.jobName}}</p>
        </el-col>
        <el-col class="info-item"><label>岗位现有人力</label>
          <p>{{info.sumCount}}</p>
        </el-col>
      </el-row>
      <el-row class="info-row__2"

              type="flex">
        <el-col class="info-item"><label>部门岗位人数</label>
          <p>{{info.depCount}}</p>
        </el-col>
        <el-col class="info-item"><label>需求数量</label>
          <p>{{info.number}}</p>
        </el-col>
      </el-row>
      <el-row class="info-row__2"

              type="flex">
        <el-col class="info-item"><label>需求职级</label>
          <p>{{info.level}}</p>
        </el-col>
        <el-col class="info-item"><label>需求学历</label>
          <p>{{education}}</p>
        </el-col>
      </el-row>
      <el-row class="info-row__2"

              type="flex">
        <el-col class="info-item"><label>需求专业</label>
          <p>{{info.professionDemand}}</p>
        </el-col>
        <el-col class="info-item"><label>到岗截止时间</label>
          <p>{{info.arrEndTime}}</p>
        </el-col>
      </el-row>
      <el-row class="info-row__1">
        <el-col class="info-item"><label>其他需求</label>
          <p>{{info.otherDemand}}</p>
        </el-col>
      </el-row>
      <el-row class="info-row__1">
        <el-col class="info-item"><label>需求技能</label>
          <p>{{info.skillDemand}}</p>
        </el-col>
      </el-row>
           <el-row class="info-row__1">
        <el-col class="info-item"><label>需求原因</label>
          <p>{{info.demandReason}}</p>
        </el-col>
      </el-row>

      <el-row class="info-row__1">
        <el-col class="info-item"><label>岗位说明</label>
          <p>{{info.jobSpecification}}</p>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
import { getDictName } from '../../../utils/dict';
export default {
  props: {
    info: {
      type: Object,
      default: () => ({})
    },
    editible: {
      type: Boolean,
      default: false
    },
    index: {
      type: Number
    },
    educationOptions: {
      type: Array,
      default: () => ([])
    }
  },
  watch: {
    info: {
      handler (val) {
        this.education = getDictName(val.educationDemand, this.educationOptions);
      },
      immediate: true
    }
  },
  methods: {
    edit () {
      this.$emit('edit', this.index);
    },
    remove () {
      this.$emit('remove', this.index);
    }
  },
  data () {
    return {
      education: ''
    };
  },
  mounted () {
  }
};
</script>
<style lang="scss" scoped>
$labelColor: #777e8c;
.info-panel {
  padding: 0 auto;
  .info-panel__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .info-title {
    font-family: PingFangSC-Semibold;
    font-size: 16px;
    color: #2B3642;
    letter-spacing: 0;
    line-height: 45px;
  }
  .info-content {
    .info-item {
      display: flex;
      padding: 10px 0;

      $labelWidth: 117px;
      label {
        font-size: 14px;
        width: $labelWidth;
        padding-right: 12px;
        line-height: 1.5em;
        color: $labelColor;
        display: block;
        font-weight: normal;
        text-align: right;
        &:after {
          content: ":";
        }
      }
      p {
        font-size: 14px;
        line-height: 1.5em;
        color:#2B3642;
        width: calc(100% - #{$labelWidth});
      }
    }
    [class*="info-row"] {
      line-height: 45px;
    }
  }
}
</style>
