<template>
    <div class="large-button">
<el-button type="text"  :icon="icon">
    <slot>
      </slot>
        </el-button>
    </div>
</template>
<script>
export default {
    name: 'large-button',
    props: {
        icon: String
    }
};
</script>
<style lang="scss">
.large-button{
  border: 1px dashed #ccc;
  text-align: center;
  height: 80px;
  margin: 0px 15px;
  padding: 20px 0px;
  font-size: 16px;
  &:hover {
    cursor: pointer;
  }
}

</style>
