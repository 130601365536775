<template>
    <div class="doc-file">
        <img src="/static/images/fj.png"
            class="doc-img-icon"
            width="35px"
            style="margin-right:10px"
            height="35px" />
        <div class="file-desc">
        <p class="file-name">{{fileName}}</p>
        <p>
   <a class="text-high-light"
             v-if="download"
            :href="url">下载</a>
        </p>

        </div>
    </div>
</template>

<script>
export default {
    props: {
        fileName: {
            default: '',
            type: String
        },
        download: {
           default: true,
           type: Boolean
        },
        url: {
            default: '',
            type: String
        }
    }
};
</script>
<style lang="scss" >
.doc-file{
    display: flex;
    height: 40px;
    align-items: center;
    .file-desc{
        height:40px;
        line-height: 40px;
    }
  p{
        line-height: 1.5em;
    }

}
</style>
