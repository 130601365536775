import { post } from "./api";
// 创建规划
export function createRecruitPlan(params) {
    return post("/v1/manpower/plan/add", { request: params });
}
// 编辑规划
export function updateRecruitPlan(params) {
    return post("/v1/manpower/plan/edit", { request: params });
}
// 规划列表
export const planList = (params) => {
    return post("/v1/manpower/plan/list", { request: params })
}
// 规划详情
export const planDetatil = (params) => {
    return post("/v1/manpower/plan/detail", { request: params })
}

export function getPositionLevelsByJob(params) {
    return post("/v1/profession/level/list", { request: params });
}
export function getPositionNum(params) {
    return post("/v1/profession/job/number", { request: params });
}
export function getMatchResultList(params) {
    return post("/v1/manpower/plan/result/list", { request: params });
}

export function getMatchResultDetail(params) {
    return post("/v1/manpower/plan/result/detail", { request: params });
}

export function getMatchResultApproveList(params) {
    return post("/v1/manpower/plan/approve/list", { request: params });
}

export function getUserCollectList(params) {
    return post("/v1/manpower/user/collect", { request: params });
}