export const Operation = {
  releaseType: '4',//发布
  detailType: '1',//详情
  editType: '2',// 编辑
  editAgainType: '3',// 再次编辑
  cancel: '5',// 取消
  copyType: '6'//副本
}

// 状态
export const PlanType = {
  all: '0',// 全部
  draft: '1', // 草稿
  waitPublish: '3', // 待发布
  publish: '2',// 发布中
  OkPublish: '4',// 已发布
  cancel: '5',// 取消
  reject: '6'// 驳回
}

export function findTreeElement (targetId, data ,props={id: 'id', children:'children'}) {
  for (let element of data) {
    if (targetId === element[props.id]) {
      return element;
    } else {
      let children = element[props.children];
      if (children && children.length > 0) {
        let res = findTreeElement(targetId, children, props);
        if(res){
          return res;
        }
      }
    }
  }
}